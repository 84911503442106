/**
 * Site header
 */
.site-header {
}

.site-subtitle {
  color: $grey-color;
  font-family: $serif-font-family;
  text-align: center;
  font-size: $base-font-size * 0.9;
  font-style: italic;
  padding-right: 7px;
  letter-spacing: 0.5px;
  margin-bottom: 25px;
  @include media-query($on-palm) {
    font-size: $base-font-size * 0.8;
  }
}

.site-title {
  font-family: "Cutive Mono", "Consolas", "Menlo", "Nimbus Mono PS", "Courier",
    "Courier New", "Lucida Typewriter", monospace;
  text-shadow: #999 0px 3px 10px;
  text-align: center;
  display: block;
  font-size: 60px;
  font-weight: 300;
  line-height: 56px;
  margin: 80px 0 20px 0;

  @include media-query($on-palm) {
    margin: 30px 0 10px 0;
  }

  &:hover {
    text-decoration: none;
  }

  &,
  &:visited {
    color: $grey-color-dark;
  }

  .site-title-text {
    &:before {
      content: "{";
      font-size: 64px;
      padding-right: 3px;
    }
    &:after {
      content: "};";
      font-size: 64px;
      padding-left: 3px;
      letter-spacing: -15px;
    }
  }
}

@include media-query($on-palm) {
  body.layout-post {
    .site-title {
      margin: 0;
      text-align: left;
      display: inline-block;
      padding-right: 20px;
      .site-title-text {
        font-weight: 600;
        letter-spacing: 1px;
        &,
        &:after,
        &:before {
          font-size: 25px;
          line-height: 25px;
        }
        &:after {
          letter-spacing: -5px;
        }
      }
    }

    .site-subtitle {
      margin-bottom: 0px;
      font-size: 16px;
      text-align: left;
      display: inline-block;
      letter-spacing: normal;
    }
  }
}

@media print {
  body.layout-post {
    .site-title {
      margin: 0;
      text-align: left;
      display: inline-block;
      padding-right: 20px;
      .site-title-text {
        font-weight: 600;
        letter-spacing: 1px;
        &,
        &:after,
        &:before {
          font-size: 25px;
          line-height: 25px;
        }
        &:after {
          letter-spacing: -5px;
        }
      }
    }

    .site-subtitle {
      margin-bottom: 0px;
      font-size: 16px;
      text-align: left;
      display: inline-block;
      letter-spacing: normal;
    }
  }
}

.site-nav {
  .page-link {
    color: $text-color;
    line-height: $base-line-height;

    // Gaps between nav items, but not on the last one
    &:not(:last-child) {
      margin-right: 20px;
    }
  }
}

.staging-warning {
  margin: 0 auto;
  max-width: 700px;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.15);
  line-height: initial;
  background: #faa;
  padding: 10px;
  a {
    text-decoration: underline;
  }
}

/**
 * Site footer
 */
.site-footer {
  border-top: 1px solid $grey-color-light;
  padding: $spacing-unit 0;
}

.footer-heading {
  font-size: 18px;
  margin-bottom: calc($spacing-unit / 2);
}

.contact-list,
.pages-list,
.social-media-list {
  list-style: none;
  margin-left: 0;
}

.footer-col-wrapper {
  font-size: 15px;
  color: $grey-color;
  @extend %clearfix;
}

.footer-col {
  float: left;
  margin-bottom: calc($spacing-unit / 2);
}

.footer-col-1,
.footer-col-2,
.footer-col-3 {
  width: 33%;
}

.footer-col-1 {
  text-align: left;
}
.footer-col-2 {
  text-align: center;
}
.footer-col-3 {
  text-align: center;
}

.read-more {
  &:visited,
  &:active {
    color: $grey-color;
  }
  color: $grey-color;
}

@include media-query($on-palm) {
  .footer-col {
    text-align: center !important;
    float: none;
    width: -webkit-calc(100% - (#{$spacing-unit} / 2));
    width: calc(100% - (#{$spacing-unit} / 2));
  }
}

@include media-query($on-palm) {
  table.collapsing-table {
    td {
      width: initial !important;
      display: block;
    }
  }
}

/**
 * Page content
 */
.page-content {
  padding: $spacing-unit 0;
}

.page-heading {
  font-size: 20px;
}

.post-list {
  margin-left: 0;
  list-style: none;

  > li {
    margin-bottom: $spacing-unit * 2;
    position: relative;
    z-index: 2;

    &:hover .post-background-image,
    .post-background-image-in-viewport {
      opacity: 1 !important;
      filter: none !important;
    }

    .post-background-image {
      transition: filter 700ms, opacity 700ms;

      filter: grayscale(1);
      background-size: cover;
      background-position: top center;
      box-shadow: #999 0px 3px 10px;
      margin: 10px 0;
      border-radius: 1px;

      height: 130px;
      opacity: 0.15;

      video {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }

      @include media-query($on-palm) {
        height: 70px;
        opacity: 0.2;
        overflow: hidden;
      }

      &.post-background-image-vcenter {
        background-position: center center;
      }
    }
  }

  @include media-query($on-palm) {
    margin-top: -20px;
  }
}

.post-meta {
  font-size: $small-font-size;
  color: $grey-color;
  @include media-query($on-palm) {
    font-size: 12px;
  }
  margin: 0;
}

.post-link {
  display: block;
  margin-top: 10px;
}

.post-link,
.post-title {
  font-size: $base-desktop-font-size * 1.3;

  line-height: 1.4;
  @include media-query($on-palm) {
    font-size: $base-font-size * 1.3;
    line-height: $base-font-size * 1.5;
  }
}

.post-title {
  margin-top: 10px;
}

.post-image-wrapper {
  width: -webkit-calc(100% + (#{$spacing-unit} * 2));
  width: calc(100% + (#{$spacing-unit} * 2));
  margin-left: -$spacing-unit;
  margin-bottom: 30px;

  @include media-query($content-width + $spacing-unit * 2) {
    width: -webkit-calc(100% + (#{$spacing-unit}));
    width: calc(100% + (#{$spacing-unit}));
    margin-left: calc(-1 * $spacing-unit/2);
    padding: initial;
  }

  @media print {
    display: none;
  }

  video {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}
.post-image {
  box-shadow: #999 0px 3px 10px;
  width: 100%;
}

.post-content,
.post-related {
  .footnotes {
    border-top: 1px solid black;
    padding-top: calc($spacing-unit * 2 / 3);
    margin-top: calc($spacing-unit * 2);
    letter-spacing: normal;

    ol > li,
    p {
      margin-bottom: initial;
      line-height: normal;
    }

    font-size: 0.7em;
    * {
      font-size: inherit;
    }
  }

  hr {
    margin: 20px 10px;
  }

  table {
    margin: 0 auto;
  }

  :not(pre) > code {
    background: #eee;
    font-size: $base-desktop-font-size * 0.9;
    border: none;

    @include media-query($on-laptop) {
      font-size: $base-font-size * 0.9;
    }
  }

  margin-bottom: $spacing-unit;
  font-family: $serif-font-family;
  font-size: $base-desktop-font-size;

  @include media-query($on-laptop) {
    font-size: $base-font-size;
  }

  h2,
  h3,
  h4 {
    position: relative;
    a {
      text-decoration: none;
      &:hover:before {
        content: "🔗";
        position: absolute;
        left: -45px;
      }
    }
  }

  line-height: 1.58;
  letter-spacing: -0.02em;

  .twitter-tweet {
    margin-left: auto;
    margin-right: auto;
  }

  todo {
    display: block;
    white-space: pre-wrap;
    padding: 5px 5px 5px 15px;
    font-weight: bold;
    font-family: sans-serif;
    color: red;
    border-left: 3px solid red;
    &:before {
      content: "TODO: ";
    }
    &:after {
      position: fixed;
      top: 0px;
      left: 0px;
      width: 100vw;
      text-align: center;
      background: red;
      color: white;
      content: "TODO ON THIS PAGE";
      display: block;
    }
  }
  p > todo {
    display: inline-block;
    border: none;
    padding: 5px;
    &:before {
      content: "";
    }
  }

  .image-gallery {
    display: grid;
    gap: $spacing-unit;
    &.grid-2x {
      grid-template-columns: auto auto;
    }
    &.grid-1x-sm {
      @include media-query($on-laptop) {
        grid-template-columns: auto;
      }
    }
  }
  .image-container {
    display: flex;
    justify-content: center;
    max-height: 80vh;
    
    img {
      margin: 0 auto;
      box-shadow: #999 0px 3px 10px;
      &.no-decoration {
        background: none;
        box-shadow: none;
      }
    }

    figcaption {
      padding-top: calc($spacing-unit / 2);
    }
    margin-bottom: $spacing-unit;
      
    &.right {
      float: right;
      margin: 30px;
    }
    @include media-query($on-laptop) {
      &.right {
        float: none;
        width: 100% !important;
        margin: 0;
        margin-bottom: $spacing-unit;
        figure {
          padding: 10px;
        }
      }
    }
  }

  h2,
  h3,
  h4,
  h5 {
    font-family: $base-font-family;
    margin-top: 30px;
    margin-bottom: 15px;
    @include media-query($on-palm) {
      margin-top: 18px;
      margin-bottom: 7px;
    }
  }

  a {
    text-decoration: underline;
    text-decoration-skip: ink;
  }

  sup {
    padding-left: 3px;
    a {
      text-decoration: none;
    }
  }

  h1 {
    font-size: $base-desktop-font-size * 1.2;

    @include media-query($on-laptop) {
      font-size: $base-font-size * 1.2;
    }
  }
  h2 {
    font-size: $base-desktop-font-size * 1.15;

    @include media-query($on-laptop) {
      font-size: $base-font-size * 1.15;
    }
  }

  h3 {
    font-size: $base-desktop-font-size * 1.1;

    @include media-query($on-laptop) {
      font-size: $base-font-size * 1.1;
    }
  }

  h4 {
    font-size: $base-desktop-font-size;

    @include media-query($on-laptop) {
      font-size: $base-font-size;
    }
  }
}
