#raimo-about {
  margin: 40px 0 20px 0;

  @include media-query($on-bigscreen) {
    display: none;
  }

  border: 0;
  box-shadow: none;
  position: fixed;
  margin: 0;
  padding: 0;
  bottom: 0;
  left: 0;
}
