// Define defaults for each variable.
$base-font-family: Lato, "Helvetica Neue", Helvetica, Arial, sans-serif !default;
$serif-font-family: 'Noto Serif', serif;
$base-font-size:   17px !default;
$base-desktop-font-size: 19px !default;
$base-font-weight: 400 !default;
$small-font-size:  $base-font-size * 0.85 !default;
$base-line-height: 2 !default;

$spacing-unit:     30px !default;

$text-color:       #111 !default;
$background-color: #fcfcfc !default;
$brand-color:      #555 !default;

$grey-color:       #737373 !default;
$grey-color-light: lighten($grey-color, 40%) !default;
$grey-color-dark:  darken($grey-color, 25%) !default;

// Width of the content area
$content-width:    800px !default;

$on-palm:          600px !default;
$on-laptop:        800px !default;
$on-bigscreen:        1200px !default;

// Use media queries like this:
// @include media-query($on-palm) {
//   .wrapper {
//     padding-right: calc($spacing-unit / 2);
//     padding-left: calc($spacing-unit / 2);
//   }
// }
@mixin media-query($device) {
  @media screen and (max-width: $device) {
    @content;
  }
}

// Import partials.
@import
  "minima/base",
  "minima/layout",
  "minima/syntax-highlighting",
  "about-page",
  "progressive-images"
;
